<template>
    <div class="search">
        <!-- 按钮 -->
        <div class="d-flex justify-content-between mb-3">
            <button v-permit="'yarn.add'" type="button" class="btn btn-success me-3" @click="addClick()">
                <i class="ri-add-fill me-1 align-bottom"></i>
                {{ $t('yarn.add') }}
            </button>
            <b-button variant="primary" v-b-toggle.searchCollapse1.searchCollapse2 @click="collapseClick">
                <i class="ri-equalizer-fill me-1 align-bottom"></i>
                {{ $t('base.advance_search') }}
            </b-button>
        </div>

        <!-- 关键字搜索 -->
        <div>
            <div class="collapse search-collapse show" id="searchCollapse1">
                <div class="d-flex justify-content-between">
                    <div class="flex-1 me-3">
                        <div class="search-box" style="margin: auto!important;width: auto!important;">
                            <input type="text" class="form-control" :placeholder="$t('yarn.keyword_placeholder')"
                                v-model="keyword" />
                            <i class="ri-search-line search-icon"></i>
                        </div>
                    </div>
                    <div class="">
                        <button type="button" class="btn btn-primary me-3" @click="searchClick()">
                            <i class="ri-search-line me-1 align-bottom"></i>
                            {{ $t('base.search') }}
                        </button>
                        <button type="button" class="btn btn-info" @click="resetClick()">
                            <i class="ri-reply-line me-1 align-bottom"></i>
                            {{ $t('base.reset') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- 高级搜索 -->
        <b-row class="collapse search-collapse" id="searchCollapse2">
            <b-col>
                <b-row>
                    <b-col :cols="cols">
                        <label class="form-label">{{ $t('attribute.code') }}</label>
                        <Multiselect id="garment" class="form-control" v-model="select_code" label="title"
                            value-prop="code" :close-on-select="true" :searchable="true" :create-option="false"
                            :options="search_list.code" @input="handlerSelect('code')" />
                    </b-col>
                    <b-col :cols="cols">
                        <label
                            class="form-label">{{ $t('yarn.linear_density') + ' (' + $t('yarn.linear_density_unit') + ')' }}</label>
                        <!-- <input type="text" class="form-control" v-model="search_linear" /> -->
                        <InputNumberRange ref="density" type="density" @numberChange="inputNumberChange">
                        </InputNumberRange>
                    </b-col>
                    <b-col :cols="cols">
                        <label
                            class="form-label">{{ $t('yarn.diameter') + ' (' + $t('yarn.diameter_unit') + ')' }}</label>
                        <!-- <input type="text" class="form-control" v-model="search_diameter" /> -->
                        <InputNumberRange ref="diameter" type="diameter" @numberChange="inputNumberChange">
                        </InputNumberRange>
                    </b-col>
                    <b-col :cols="cols">
                        <label
                            class="form-label">{{ $t('attribute.cost') + ' (' + $t('attribute.price_unit1') + ')' }}</label>
                        <InputNumberRange ref="cost" type="cost" @numberChange="inputNumberChange"></InputNumberRange>
                    </b-col>
                    <b-col :cols="cols">
                        <label
                            class="form-label">{{ $t('attribute.stock') + ' (' + $t('attribute.stock_unit1') + ')' }}</label>
                        <InputNumberRange ref="stock" type="stock" @numberChange="inputNumberChange"></InputNumberRange>
                    </b-col>
                    <b-col :cols="cols">
                        <label class="form-label">{{ $t('yarn.twist') + ' (' + $t('yarn.twist_unit') + ')' }}</label>
                        <InputNumberRange ref="twist" type="twist" @numberChange="inputNumberChange"></InputNumberRange>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="auto">
                <div class="row" style="position: relative; top: 100%; transform: translateY(-100%);">
                    <div>
                        <div class="col-auto">
                            <button type="button" class="btn btn-primary me-3" @click="searchClick()">
                                <i class="ri-search-line me-1 align-bottom"></i>
                                {{ $t('base.search') }}
                            </button>
                            <button type="button" class="btn btn-info" @click="resetClick()">
                                <i class="ri-reply-line me-1 align-bottom"></i>
                                {{ $t('base.reset') }}
                            </button>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import InputNumberRange from '@/views/components/InputNumberRange';
    export default {
        components: {
            InputNumberRange
        },
        emits: ['update:searchInfo', 'initYarn'],
        props: {
            searchInfo: {
                type: Object,
                default: () => {}
            },
            search_list: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                select_code: '',
                select_mode_code: 'tags',
                density: {
                    start: '',
                    end: ''
                },
                diameter: {
                    start: '',
                    end: ''
                },
                cost: {
                    start: '',
                    end: ''
                },
                stock: {
                    start: '',
                    end: ''
                },
                twist: {
                    start: '',
                    end: ''
                },
                keyword: '',
                searchVisiable: true,
                cols: 3
            };
        },
        methods: {
            inputNumberChange(data, type) {
                if (type == 'density') {
                    this.density.start = data[0]
                    this.density.end = data[1]
                } else if (type == 'diameter') {
                    this.diameter.start = data[0]
                    this.diameter.end = data[1]
                } else if (type == 'cost') {
                    this.cost.start = data[0]
                    this.cost.end = data[1]
                } else if (type == 'stock') {
                    this.stock.start = data[0]
                    this.stock.end = data[1]
                } else if (type == 'twist') {
                    this.twist.start = data[0]
                    this.twist.end = data[1]
                }
            },
            collapseClick() {
                this.searchVisiable = !this.searchVisiable;
                this.resetClick();
            },
            /* 切换多选框展示样式 */
            handlerSelect(type) {
                let selectType = `select_mode_${type}`;
                let selectData = `search_${type}`;
                if (this[selectData] && this[selectData].length >= 3) {
                    this[selectType] = 'multiple';
                } else {
                    this[selectType] = 'tags';
                }
            },
            addClick() {
                this.$router.push(`/yarn_detail/?type=add`);
            },
            updateParams() {
              let info = {};
              if (this.select_code && this.select_code.length > 0) {
                info['code'] = this.select_code
              }
              if (this.keyword && this.keyword.length > 0) {
                info['keyword'] = this.keyword;
              }
              // density
              if (this.density.start && this.density.start.length > 0) {
                info['linear_density_min'] = this.density.start;
              }
              if (this.density.end && this.density.end.length > 0) {
                info['linear_density_max'] = this.density.end;
              }
              // diameter
              if (this.diameter.start && this.diameter.start.length > 0) {
                info['diameter_min'] = this.diameter.start;
              }
              if (this.diameter.end && this.diameter.end.length > 0) {
                info['diameter_max'] = this.diameter.end;
              }
              // cost
              if (this.cost.start && this.cost.start.length > 0) {
                info['price_min'] = this.cost.start;
              }
              if (this.cost.end && this.cost.end.length > 0) {
                info['price_max'] = this.cost.end;
              }
              // stock
              if (this.stock.start && this.stock.end.length > 0) {
                info['inventory_min'] = this.stock.start;
              }
              if (this.stock.end && this.stock.end.length > 0) {
                info['inventory_max'] = this.stock.end;
              }
              // twist
              if (this.twist.start && this.twist.start.length > 0) {
                info['twist_min'] = this.twist.start;
              }
              if (this.twist.end && this.twist.end.length > 0) {
                info['twist_max'] = this.twist.end;
              }
              this.$emit('update:searchInfo', info);
            },
            searchClick() {
              this.updateParams();
            },
            resetClick() {
                this.select_code = ''
                this.density = {
                    start: '',
                    end: ''
                };
                if (this.$refs.density) {
                    this.$refs.density.reset();
                }
                this.diameter = {
                    start: '',
                    end: ''
                };
                if (this.$refs.diameter) {
                    this.$refs.diameter.reset();
                }
                this.cost = {
                    start: '',
                    end: ''
                };
                if (this.$refs.cost) {
                    this.$refs.cost.reset();
                }
                this.stock = {
                    start: '',
                    end: ''
                };
                if (this.$refs.stock) {
                    this.$refs.stock.reset();
                }
                this.twist = {
                    start: '',
                    end: ''
                };
                if (this.$refs.twist) {
                    this.$refs.twist.reset();
                }
                this.keyword = '';
                this.updateParams();
            }
        }
    };
</script>

<style scoped lang="scss"></style>

<style>
    .form-label {
        margin-top: 10px;
    }
</style>

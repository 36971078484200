import {
	createAPI
} from './http'
import axios from "axios";
import {
	API_SVC_HOST
} from '../config/common';


const api = createAPI();

/* 登录 */
export function login(params) {
	return api.post('/api/login', params)
}

/* ------ 服装 ------ */

/* 获取服装列表 */
export function getGarmentList(params) {
	return api.get('/api/garment', params)
}

/* 新增服装数据 */
export function postGarmentDetail(params) {
	return api.post('/api/garment', params)
}

/* 获取服装详情 */
export function getGarmentDetail(id) {
	return api.get('/api/garment/detail/' + id)
}



/* 修改服装数据 */
export function putGarmentDetail(id, params) {
	return api.put('/api/garment/detail/' + id, params)
}

/* 新增服装数据 */
export function deleteGarmentDetail(id) {
	return api.delete('/api/garment/detail/' + id)
}

/* ------ 织物 ------ */

/* 获取织物列表 */
export function getFabricList(params) {
	return api.get('/api/fabric', params)
}

/* 获取织物详情 */
export function getFabricDetail(id) {
	return api.get('/api/fabric/detail/' + id)
}

/* 修改织物详情 */
export function putFabricDetail(id, params) {
	return api.put('/api/fabric/detail/' + id, params)
}

/* 删除织物 */
export function deleteFabricDetail(id) {
	return api.delete('/api/fabric/detail/' + id)
}

/* 新增织物数据 */
export function postFabricDetail(params) {
	return api.post('/api/fabric', params)
}

/**
 * mmt/ftt数据
 */
export function getReport(params) {
	return api.get(`/api/report/${params.type}/${params.id}`, params)
}

/* ------ 纱线 ------ */

/* 获取纱线列表 */
export function getYarnList(params) {
	return api.get('/api/yarn', params)
}

/* 获取纱线详情 */
export function getYarnDetail(id) {
	return api.get('/api/yarn/detail/' + id)
}

/* 修改纱线详情 */
export function putYarnDetail(id, params) {
	return api.put('/api/yarn/detail/' + id, params)
}

/* 删除纱线 */
export function deleteYarnDetail(id) {
	return api.delete('/api/yarn/detail/' + id)
}

/* 新增纱线数据 */
export function postYarnDetail(params) {
	return api.post('/api/yarn', params)
}

/* ------ 纤维 ------ */

/* 获取纤维列表 */
export function getFiberList(params) {
	return api.get('/api/fiber', params)
}

/* 获取纤维详情 */
export function getFiberDetail(id) {
	return api.get('/api/fiber/detail/' + id)
}

/* 修改纤维详情 */
export function putFiberDetail(id, params) {
	return api.put('/api/fiber/detail/' + id, params)
}

/* 删除纤维 */
export function deleteFiberDetail(id) {
	return api.delete('/api/fiber/detail/' + id)
}

/* 新增纤维数据 */
export function postFiberDetail(params) {
	return api.post('/api/fiber', params)
}


/* ------ 家纺 ------ */

export function getHomeTextileList(params) {
	return api.get('/api/homeTextile', params)
}


/* 新增服装数据 */
export function postHomeTextileDetail(params) {
	return api.post('/api/homeTextile', params)
}

/* 获取服装详情 */
export function getHomeTextileDetail(id) {
	return api.get('/api/homeTextile/detail/' + id)
}

/* 修改服装数据 */
export function putHomeTextileDetail(id, params) {
	return api.put('/api/homeTextile/detail/' + id, params)
}

/* 新增服装数据 */
export function deleteHomeTextileDetail(id) {
	return api.delete('/api/homeTextile/detail/' + id)
}


/* ------ 纺织品 ------ */

export function getTextileBedwareList(params) {
	return api.get('/api/textile_bedware', params)
}


/* 新增服装数据 */
export function postTextileBedwareDetail(params) {
	return api.post('/api/textile_bedware', params)
}

/* 获取服装详情 */
export function getTextileBedwareDetail(id) {
	return api.get('/api/textile_bedware/detail/' + id)
}

/* 修改服装数据 */
export function putTextileBedwareDetail(id, params) {
	return api.put('/api/textile_bedware/detail/' + id, params)
}

/* 新增服装数据 */
export function deleteTextileBedwareDetail(id) {
	return api.delete('/api/textile_bedware/detail/' + id)
}
export function getTextileHomeDecorList(params) {
	return api.get('/api/textile_home_decor', params)
}


/* 新增服装数据 */
export function postTextileHomeDecorDetail(params) {
	return api.post('/api/textile_home_decor', params)
}

/* 获取服装详情 */
export function getTextileHomeDecorDetail(id) {
	return api.get('/api/textile_home_decor/detail/' + id)
}

/* 修改服装数据 */
export function putTextileHomeDecorDetail(id, params) {
	return api.put('/api/textile_home_decor/detail/' + id, params)
}

/* 新增服装数据 */
export function deleteTextileHomeDecorDetail(id) {
	return api.delete('/api/textile_home_decor/detail/' + id)
}

export function getTextileIndustrialList(params) {
	return api.get('/api/textile_industrial', params)
}


/* 新增服装数据 */
export function postTextileIndustrialDetail(params) {
	return api.post('/api/textile_industrial', params)
}

/* 获取服装详情 */
export function getTextileIndustrialDetail(id) {
	return api.get('/api/textile_industrial/detail/' + id)
}

/* 修改服装数据 */
export function putTextileIndustrialDetail(id, params) {
	return api.put('/api/textile_industrial/detail/' + id, params)
}

/* 新增服装数据 */
export function deleteTextileIndustrialDetail(id) {
	return api.delete('/api/textile_industrial/detail/' + id)
}
export function getTextileKitchenList(params) {
	return api.get('/api/textile_kitchen', params)
}


/* 新增服装数据 */
export function postTextileKitchenDetail(params) {
	return api.post('/api/textile_kitchen', params)
}

/* 获取服装详情 */
export function getTextileKitchenDetail(id) {
	return api.get('/api/textile_kitchen/detail/' + id)
}

/* 修改服装数据 */
export function putTextileKitchenDetail(id, params) {
	return api.put('/api/textile_kitchen/detail/' + id, params)
}

/* 新增服装数据 */
export function deleteTextileKitchenDetail(id) {
	return api.delete('/api/textile_kitchen/detail/' + id)
}

/* 上传文件 */
export function uploadFile(file) {
	const formData = new FormData()
	formData.append('file', file)
	return axios({
		baseUrl: API_SVC_HOST,
		url: `/api/upload`,
		method: 'post',
		headers: {
			'Content-Type': 'multipart/form-data'
		},
		data: formData
	})
}

/* 获取用户信息 */
export function getUserInfo(params) {
	return api.get('/api/user_center', params)
}

/* 修改用户信息 */
export function postUserInfo(params) {
	return api.post('/api/user_center', params)
}

/* 修改用户信息 */
export function postPassWord(params) {
	return api.post('/api/user_change_password', params)
}

/**
 * 桑基图
 */
export function getSankeyChartInfo(params) {
	return api.post('/api/sankey', params)
}

/**
 * 角色列表
 */
export function getRole(params) {
	return api.get('/api/role', params)
}

/**
 * 新建角色
 */
export function createRole(params) {
	return api.post('/api/role', params)
}

/**
 * 删除角色
 */
export function deleteRole(id) {
	return api.delete(`/api/role/detail/${id}`)
}

/**
 * 修改角色
 */
export function editRole(params) {
	return api.put(`/api/role/detail/${params.id}`, params)
}

/**
 * 角色详情
 */
export function roleDetail(id) {
	return api.get(`/api/role/detail/${id}`)
}

/**
 * 所有角色（用户列表页筛选角色使用、创建用户页面使用）
 */
export function getRoleSelect() {
	return api.get('/api/role_select')
}

/**
 * 设置角色权限
 */
export function setRolePermission(params) {
	return api.post('/api/role_permission', params)
}

/**
 * 所有角色-树形
 */
export function roleTreeList(params) {
	return api.get('/api/role_permission', params)
}

/**
 * 用户列表
 */
export function getUser(params) {
	return api.get('/api/user', params)
}

/**
 * 新建用户
 */
export function createUser(params) {
	return api.post('/api/user', params)
}

/**
 * 删除用户
 */
export function deleteUser(id) {
	return api.delete(`/api/user/detail/${id}`)
}

/**
 * 修改用户
 */
export function editUser(params) {
	return api.put(`/api/user/detail/${params.id}`, params)
}

/**
 * 用户详情
 */
export function userDetail(id) {
	return api.get(`/api/user/detail/${id}`)
}

/**
 * 设置用户权限
 */
export function setUserPermission(params) {
	return api.post('/api/user_permission', params)
}

/**
 * 权限列表
 */
export function getPermission(params) {
	return api.get('/api/permissions', params)
}

/**
 * 品目编码列表
 */
export function getHSCode(params) {
	return api.get('/api/hs_code', params)
}

/**
 * 获取数据库指标
 */
export function getDbIndex(params) {
	return api.get('/api/db_index', params)
}
// 获取hscode关联的数据库指标
export function getHscodeDbIndex(params) {
	return api.get('/api/hscode_db_index', params)
}

export function postDbIndex(params) {
	return api.post('/api/db_index', params)
}

/**
 * 新增或修改时，选择织物的列表
 */
export function getFabricOptions(params) {
	return api.get('/api/fabric_options', params)
}

/**
 * 新增或修改时，选择纱线的列表
 */
export function getYarnOptions(params) {
	return api.get('/api/yarn_options', params)
}

/**
 * 新增或修改时，选择纤维的列表
 */
export function getFibreOptions(params) {
	return api.get('/api/fibre_options', params)
}

export function getPublicGarmentDetail(id) {
	return api.get('/api/public/garment_detail/' + id)
}

export function getPublicFabricDetail(id) {
	return api.get('/api/public/fabric_detail/' + id)
}

export function getPublicYarnDetail(id) {
	return api.get('/api/public/yarn_detail/' + id)
}

export function getPulicFibreDetail(id) {
	return api.get('/api/public/fibre_detail/' + id)
}

export function getPublicHomeTextileDetail(id) {
	return api.get('/api/public/hometextile_detail/' + id)
}

export function getPublicTextileDetail(id, data_type) {
	return api.get('/api/public/textile_detail/' + id, {data_type: data_type})
}


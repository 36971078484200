// import _ from 'lodash'

export const tableMixin = {
    data() {
        return {
            pagination: {
                total: 0,
                currentPage: 1,
                pageOption: [],
                pageSize: 10,
                pageSizeOption: [10, 20, 30, 40, 50],
            },
            params: {},
            tableData: [],
            tableDataLoading: false,
        }
    },
    computed: {
        requestParams() {
            return {
                skip: (this.pagination.currentPage - 1) * this.pagination.pageSize,
                limit: this.pagination.pageSize,
                ...this.params
            }
        }
    },
    methods: {
        // 获取第一页数据
        getFirstPage() {
            if (this.tableDataLoading) {
                return
            }
            this.tableDataLoading = true
            this.tableData = []
            this.pagination.total = 0
            if (this.pagination.currentPage !== 1) {
                this.pagination.currentPage = 1
            } else {
                this.requestList(this.requestParams)
            }
        },
        // 刷新当前页数据
        refreshList() {
            this.tableDataLoading = true
            this.tableData = []
            this.pagination.total = 0
            if (this.pagination.currentPage > 1 && this.pagination.total - 1 <= (this.pagination.currentPage - 1) * this.pagination.pageSize) {
                this.pagination.currentPage -= 1
            } else {
                this.requestList(this.requestParams)
            }
        },
        // 请求完成后渲染数据
        updateData(payload) {
            this.tableDataLoading = false
            payload.data.forEach((l, index) => {
                l.number = this.pagination.pageSize * (this.pagination.currentPage - 1) + index + 1
            })
            this.tableData = payload.data
            this.pagination.total = payload.total;
            this.setPageOption()
        },
        setPageOption() {
            this.pagination.pageOption = []
            let numberOfPages = Math.ceil(this.pagination.total / this.pagination.pageSize);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pagination.pageOption.push(index);
            }
        }
    },
    watch: {
        'pagination.pageSize': {
            handler(val) {
                if (val) {
                    this.getFirstPage()
                }
            }
        },
        'pagination.currentPage': {
            immediate: true,
            deep: true,
            handler() {
                this.tableDataLoading = true
                this.tableData = []
                this.pagination.total = 0
                this.requestList(this.requestParams)
            }
        },
        params: {
            immediate: true,
            deep: true,
            handler() {
                this.getFirstPage()
            }
        }
    }
}

<template>
	<!-- <div>
		<div class="form-control" :class="{ 'is-disabled': disabled }">
			<div class="flex">
				<div class="from">
					<input
						ref="input_from"
						v-model="userInputForm"
						:disabled="disabled"
						placeholder="最小值"
						@blur="handleBlurFrom"
						@focus="handleFocusFrom"
						@input="handleInputFrom"
						@change="handleInputChangeFrom"
					/>
				</div>
				<div class="center">
					<span>{{ $t('base.to') }}</span>
				</div>
				<div class="to">
					<input
						ref="input_to"
						v-model="userInputTo"
						:disabled="disabled"
						placeholder="最大值"
						@blur="handleBlurTo"
						@focus="handleFocusTo"
						@input="handleInputTo"
						@change="handleInputChangeTo"
					/>
				</div>
			</div>
		</div>
	</div> -->
	<div>
		<div class="form-control" :class="{ 'is-disabled': disabled }">
			<b-row>
				<b-col>
					<div class="from">
						<input
							ref="input_from"
							v-model="userInputForm"
							:disabled="disabled"
							@input="fromInput"
							@change="fromChange"
						/>
					</div>
				</b-col>
				<b-col cols="auto">
					<div class="center">
						<span style="color: #999;">{{ $t('base.to') }}</span>
					</div>
				</b-col>
				<b-col>
					<div class="to">
						<input
							ref="input_to"
							v-model="userInputTo"
							:disabled="disabled"
							@blur="handleBlurTo"
							@focus="handleFocusTo"
							@input="toInput"
							@change="toChange"
						/>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
export default {
	name: 'InputNumberRange',
	props: {
        type: {
            type: String,
            default: ''
        },
		// 初始化范围
		value: { required: true },

		// 是否禁用
		disabled: {
			type: Boolean,
			default: false
		},

		// 精度参数
		precision: {
			type: Number,
			default: 0,
			validator(val) {
				return val >= 0 && val === parseInt(val, 10);
			}
		}
	},

	data() {
		return {
			userInputForm: '',
			userInputTo: ''
		};
	},

	watch: {
		value: {
			immediate: true,
			handler(value) {
				/** 初始化范围 */
				if (value instanceof Array && this.precision !== undefined) {
					this.userInputForm = typeof value[0] === 'number' ? value[0] : null;
					this.userInputTo = typeof value[1] === 'number' ? value[1] : null;
				}
			}
		}
	},

	methods: {
		reset() {
			this.userInputForm = ''
			this.userInputTo = ''
		},
		// 根据精度保留数字
		toPrecision(num, precision) {
			if (precision === undefined) {
				precision = 0;
			}
			return parseFloat(Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision));
		},

		handleBlurFrom(event) {
			this.$emit('blurfrom', event);
		},

		handleFocusFrom(event) {
			this.$emit('focusfrom', event);
		},

		handleBlurTo(event) {
			this.$emit('blurto', event);
		},

		handleFocusTo(event) {
			this.$emit('focusto', event);
		},

		handleInputFrom(value) {
			this.$emit('inputfrom', value);
		},

		handleInputTo(value) {
			this.$emit('inputto', value);
		},

		// from输入框change事件
		handleInputChangeFrom(value) {
			// 如果是非数字空返回null
			if (isNaN(value) || value === '') {
				this.$emit('input', [null, this.userInputTo]);
				this.$emit('changefrom', this.userInputForm);
				return;
			}

			// 初始化数字精度
			this.userInputForm = this.setPrecisionValue(value);

			// 如果from > to 将from值替换成to
			if (typeof this.userInputTo === 'number') {
				this.userInputForm = parseFloat(this.userInputForm) <= parseFloat(this.userInputTo) ? this.userInputForm : this.userInputTo;
			}
			this.$emit('input', [this.userInputForm, this.userInputTo]);
			this.$emit('changefrom', this.userInputForm);
		},
		fromInput() {
			this.userInputForm = this.userInputForm.replace(/[^0-9.]/g, '')
			this.$emit('numberChange', [this.userInputForm, this.userInputTo], this.type);
		},
		fromChange() {
			this.$emit('numberChange', [this.userInputForm, this.userInputTo], this.type);
		},
		// to输入框change事件
		handleInputChangeTo(value) {
			// 如果是非数字空返回null
			if (isNaN(value) || value === '') {
				this.$emit('input', [this.userInputForm, null]);
				this.$emit('changefrom', this.userInputTo);
				return;
			}

			// 初始化数字精度
			this.userInputTo = this.setPrecisionValue(value);

			// 如果to < tfrom 将to值替换成from
			if (typeof this.userInputForm === 'number') {
				this.userInputTo = parseFloat(this.userInputTo) >= parseFloat(this.userInputForm) ? this.userInputTo : this.userInputForm;
			}
			this.$emit('input', [this.userInputForm, this.userInputTo]);
			this.$emit('changeto', this.userInputTo);
		},
		toInput() {
			this.userInputTo = this.userInputTo.replace(/[^0-9.]/g, '')
			this.$emit('numberChange', [this.userInputForm, this.userInputTo], this.type);
		},
		toChange() {
			this.$emit('numberChange', [this.userInputForm, this.userInputTo], this.type);
		},
		// 设置成精度数字
		setPrecisionValue(value) {
			if (this.precision !== undefined) {
				const val = this.toPrecision(value, this.precision);
				return val;
			}
			return null;
		}
	}
};
</script>
<style lang="scss" scoped>
// ::v-deep .input--mini .input__inner {
// 	border: 0px;
// 	margin: 0;
// 	padding: 0 15px;
// 	background-color: transparent;
// }
input {
	text-align: center;
	outline: none;
	-webkit-appearance: button;
	-webkit-appearance: none;
	border-radius: 0;
	background: none;
	border: none;
	// outline: none;
	// background: transparent;
	// border: none;
	// outline: medium;
	width: 100%;
	// height: 50px;
	// border-style:none
}
.input-number-range {
	background-color: #fff;
	border: 1px solid #dcdfe6;
	border-radius: 4px;
}
.flex {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: left;
	align-items: center;
	.center {
		margin-top: 1px;
	}
}
// .is-disabled {
// 	background-color: #eef0f6;
// 	border-color: #e4e7ed;
// 	color: #c0c4cc;
// 	cursor: not-allowed;
// }
</style>

<template>
  <div class="form p-3" v-if="tableInfo.length">
    <!-- Bordered Tables -->
    <table class="table table-bordered table-nowrap">
      <thead class="table-light">
        <tr>
          <th scope="col" v-for="(head, index) in table_head" :key="index">
            {{ head }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(info, index) in tableInfo"
          :key="index"
          style="vertical-align: middle"
        >
          <td scope="row" class="text-center">
            {{ info.number }}
          </td>
          <td>
            <a href="javascript:;" @click="handlerControl('view', info)">{{
              info.yarn_id
            }}</a>
          </td>
          <td class="text-center">
            <a href="javascript:;" @click="handleImgPreview(info)">
              <img
                :src="getPicture(info)"
                style="width: 6rem; height: 3rem; object-fit: contain"
                @error="
                  (e) => {
                    e.target.src = defaultImage;
                  }
                "
              />
            </a>
          </td>
          <td>{{ info.type }}</td>
          <td>{{ info.diameter }}</td>
          <td>{{ info.twist }}</td>
          <td>{{ info.linear_density }}</td>
          <td>{{ info.brand_name }}</td>
          <td>{{ info.supplier_name }}</td>
          <td>{{ info.price }}</td>
          <td>{{ info.inventory }}</td>
          <td v-if="showOperation()">
            <div class="dropdown">
              <a
                href="#"
                role="button"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="ri-more-2-fill"></i>
              </a>
              <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li v-permit="'yarn.show'">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('view', info)"
                  >
                    {{ $t("base.to_view") }}
                  </div>
                </li>
                <li v-permit="'yarn.modify'" v-if="info['can_edit']">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('edit', info)"
                  >
                    {{ $t("base.edit") }}
                  </div>
                </li>
                <li v-permit="'yarn.delete'" v-if="info['can_edit']">
                  <div
                    class="dropdown-item cursor"
                    @click="handlerControl('delete', info)"
                  >
                    {{ $t("base.delete") }}
                  </div>
                </li>
              </ul>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else>
    <Empty v-if="!tableLoading"></Empty>
    <Loading v-else></Loading>
  </div>
  <message-alert
    :alertInfo="alertInfo"
    v-if="formValidataAlert"
  ></message-alert>
  <PreviewImg
    v-model:showPreviewImg="showPreviewImg"
    :src="src"
    v-if="showPreviewImg"
  ></PreviewImg>
</template>

<script>
import PreviewImg from "@/components/common/PreviewImg";
import defaultImage from "@/assets/image.png";
import { deleteYarnDetail } from "@/service/index";
import Swal from "sweetalert2";
import _ from "lodash";
export default {
  components: {
    PreviewImg,
  },
  props: {
    tableInfo: {
      type: Array,
      default: () => [],
    },
    tableLoading: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["initYarn"],
  data() {
    return {
      defaultImage,
      table_head: [
        this.$t("base.No"),
        this.$t("attribute.id"),
        this.$t("attribute.picture"),
        this.$t("attribute.type"),
        this.$t("yarn.diameter") + " (" + this.$t("yarn.diameter_unit") + ")",
        this.$t("yarn.twist") + " (" + this.$t("yarn.twist_unit") + ")",
        this.$t("yarn.linear_density") +
          " (" +
          this.$t("yarn.linear_density_unit") +
          ")",
        this.$t("attribute.brand"),
        this.$t("attribute.supplier"),
        this.$t("attribute.cost") +
          " (" +
          this.$t("attribute.price_unit1") +
          ")",
        this.$t("attribute.stock") +
          " (" +
          this.$t("attribute.stock_unit1") +
          ")",
        this.$t("base.operation"),
      ],
      alertInfo: {
        variant: "danger",
        content: "",
        icon: "ri-error-warning-line",
      },
      formValidataAlert: false,
      showPreviewImg: false,
      src: "",
    };
  },
  created() {
    if (!this.showOperation()) {
      this.table_head.pop();
    }
  },
  methods: {
    handlerControl(type, info) {
      if (type == "delete") {
        Swal.fire({
          title: this.$t("base.delete_alert_title"),
          text: this.$t("base.delete_alert_info"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#f46a6a",
          confirmButtonColor: "#34c38f",
          confirmButtonText: this.$t("base.done"),
          cancelButtonText: this.$t("base.cancel"),
        }).then((result) => {
          if (result.isConfirmed) {
            deleteYarnDetail(info.id)
              .then((res) => {
                if (res) {
                  Swal.fire(
                    this.$t("base.success"),
                    this.$t("base.delete_alert_completed"),
                    "success"
                  );
                }
              })
              .then(() => {
                this.$emit("initYarn");
              })
              .catch((e) => {
                this.formValidataAlert = true;
                this.alertInfo = {
                  variant: "danger",
                  content: e.message,
                  icon: "ri-alert-line",
                };
                setTimeout(() => {
                  this.formValidataAlert = false;
                }, 4000);
              });
          }
        });
      } else {
        let url = this.$router.resolve({
          path: "/yarn_detail",
          query: { id: info.id, type: type },
        });
        window.open(url.href, "_blank");
        // this.$router.push(`/yarn_detail/?id=${info.id}&type=${type}`);
      }
    },
    getPicture(info) {
      const pictures = _.get(info, "pictures", []);
      if (pictures == undefined || pictures.length < 1) {
        return "";
      }
      return pictures[0].url;
    },
    handleImgPreview(item) {
      this.src = this.getPicture(item);
      if (this.src == "") {
        return;
      }
      this.showPreviewImg = true;
    },
    showOperation() {
      const params = {
        list: ["yarn.show", "yarn.modify", "yarn.delete"],
        type: "some",
      };
      return this.$permit(params);
    },
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <div class="modal fade show" style="display: block">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- head -->
        <div class="modal-header">
          <h5 class="modal-title">{{ $t("base.preview") }}</h5>
          <button
            type="button"
            class="btn-close"
            @click="handleClose()"
          ></button>
        </div>
        <div class="modal-body text-center">
          <b-img :src="src" alt="" class="rounded" style="width: 50rem"></b-img>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</template>
<script>
export default {
  emits: ["update:showPreviewImg"],
  props: {
    src: String,
    showPreviewImg: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClose() {
      this.$emit("update:showPreviewImg", false);
    },
  },
};
</script>
<style scoped lang='scss'>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 55rem !important;
  }
}
</style>

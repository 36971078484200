import axios from 'axios';
import {
	API_SVC_HOST
} from '../config/common';
// import _ from 'lodash';
import store from "@/state/store";
import i18n from "@/i18n"
import { getAcceptLanguage } from '@/i18n'

function urlFormat(url) {
	// return _.trimEnd(_.trim(API_SVC_HOST, '"'), "/") + "/" + _.trimStart(url, "/")
	return url
}

// 创建axios实例 application/x-www-data-urlencoded;charset=UTF-8 application/json
var service = axios.create({
	baseURL: API_SVC_HOST,
	timeout: 300000,
	xhrFields: {
		withCredentials: true
	}
})

service.interceptors.request.use(
	reqConfig => {
		reqConfig.headers["Authorization"] = getToken()
		reqConfig.headers["Content-Type"] = "application/json"
		reqConfig.headers["Accept-Language"] = getAcceptLanguage()
		// reqConfig.headers["Content-Encoding"]= "gzip";
		return reqConfig;
	},
	err => Promise.reject(err),
)

function getToken() {
	let storeData = store.state.login.menuList || sessionStorage.getItem('set_menu_list');
	if (storeData) {
		storeData = JSON.parse(storeData)
		return storeData.token
	}
	return ''
}

function request(url, params, options, type) {
	options = options || {}
	if (options && options.headers) {
		service.defaults.headers = Object.assign({}, service.defaults.headers, options.headers)
	}
	return new Promise((resolve, reject) => {
		service[type](urlFormat(url, options), params).then((response) => {
			resolve(response.data)
		}).catch((err) => {
			if (err && err.response && err.response.status == 403) {
				const data = {
					message: i18n.global.t('base.request403')
				}
				reject(data);
			} else {
				if (err.response && err.response.data && err.response.data.message) {
					reject(err.response.data.message);
				} else {
					reject(err.response && err.response.data && err.response.data.payload || err);
				}
			}
		});
	});
}

export function createAPI() {
	return {
		post(url, params, options) {
			return request(url, params, options, 'post');
		},
		get(url, params, options) {
			return request(url, {
				params
			}, options, 'get');
		},
		put(url, params, options) {
			params = Object.assign({}, params, {
				_method: 'PUT'
			});
			return request(url, params, options, 'put');
		},
		delete(url, params, options) {
			params = Object.assign({}, params, {
				_method: 'DELETE'
			});
			return request(url, {
				params
			}, options, 'delete');
		}
	};
}
